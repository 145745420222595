<template>
    <div class="commonTitle flex jbet">
        <div class="title flex aend">
            <div class="cn">{{ cn }}</div>
            <div class="en">{{ en }}</div>
        </div>
        <div class="tablist flex acen">
            <template v-for="(item, index) in list">
                <div class="tabitem tabType1" :key="item.id" v-if="type == 1" @mouseover="mouseover(item)"
                    :class="{ active: active == item.id }">{{ item.name
                    }}<span v-if="index != list.length - 1">/</span> </div>
                <div class="tabitem tabType2" :key="item.id" v-else @mouseover="mouseover(item)" :class="{ active: active == item.id }">{{
                    item.name }}</div>
            </template>

        </div>
    </div>
</template>
<script>
export default {
    name: 'commonTitle',
    props: {
        cn: {
            type: String,
            default: ''
        },
        en: {
            type: String,
            default: ''
        },
        type: {
            type: Number,
            default: 1
        },
        list: {
            type: Array,
            default: () => [],
        },
        active: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            keyword: '',
        }
    },
    methods: {
        mouseover(tab) {
            let type = ''
            if (this.cn == '信息公开') {
                type = 'info'
            } else if (this.cn == '公益项目') {
                type = 'project'
            }else if (this.cn == '社区基金') {
                type = 'fund'
            }else if (this.cn == '政策法规') {
                type = 'policy'
            }
            this.$emit('changTab', { id: tab.id, type })
        }
    }
}
</script>
<style lang="less" scoped>
.commonTitle {
    margin-bottom: 30px;

    .title {
        font-size: 30px;
        color: #000000;
        font-weight: 500;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            bottom: -10px;
            width: 50px;
            height: 4px;
            background: #FC1527;
        }

        .en {
            margin-left: 10px;
            font-weight: 400;
            font-size: 24px;
            color: #CECECE;
            text-transform: uppercase
        }
    }

    .tablist {
        .tabType1 {
            font-weight: 400;
            font-size: 18px;
            color: #666666;
            cursor: pointer;

            &:hover {
                color: #FC1527;

            }

            span {
                margin: 0 20px;
                color: #666666;

            }

            &.active {
                color: #FC1527;
            }
        }



        .tabType2 {
            width: 116px;
            height: 30px;
            background: #3D3D3D;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-weight: 400;
            font-size: 18px;
            margin-left: 20px;
            cursor: pointer;

            &:hover {
                background: #FC1527;

            }

            &.active {
                background: #FC1527;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .commonTitle {
        flex-direction: column;
        margin-bottom: 10px;

        .title {
            font-size: 14px;

            &::before {
                height: 2px;
            }

            .en {
                margin-left: 4px;
                font-size: 12px;
            }
        }

        .tablist {
            justify-content: flex-end;
            margin-top: 20px;
            flex-wrap: wrap;

            .tabType1 {
                font-size: 12px;
                white-space: nowrap;

                span {
                    margin: 0 4px;
                }
            }



            .tabType2 {
                width: auto;
                height: auto;
                padding: 5px 10px;
                line-height: inherit;
                font-size: 12px;
                margin-left: 10px;
            }
        }
    }
}</style>