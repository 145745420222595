<template>
    <el-drawer :visible.sync="detailDrawer" ref="drawer" :size="450" :with-header="false">
        <div v-loading="loading">
            <div class="banner">
                <el-carousel :loop="true" :interval="5000">
                    <el-carousel-item v-for="(item, index) in info.banner" :key="index">
                        <el-image style="width: 100%;" :src="item" fit="cover"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="main">
                <div class="card">
                    <img class="jk" v-if="info.status == 0" src="../assets/2@2x.png" mode="widthFix" />
                    <img class="jk" v-if="info.status == 1" src="../assets/ing@2x.png" mode="widthFix" />
                    <img class="jk" v-if="info.status == 2" src="../assets/1@2x.png" mode="widthFix" />
                    <img class="jk" v-if="info.status == 3" src="../assets/over@2x.png" mode="widthFix" />
                    <div class="title">
                        {{ info.title || '' }}
                    </div>
                    <div class="content">
                        {{ info.sketch || '' }}
                    </div>
                    <div class="jigou flex acen">
                        收款机构: {{ info.receivingInstitution || '' }}
                    </div>
                    <div class="jigou flex acen">
                        执行机构: {{ info.executeInstitution || '' }}
                    </div>
                </div>
                <div class="card" id="xiangmu">
                    <div class="ctitle">
                        项目信息
                    </div>
                    <div class="content" v-html="info.information"></div>
                </div>
                <div class="card" id="jigou">
                    <div class="ctitle">
                        项目机构
                    </div>
                    <div class="insMain">
                        <div class="insItem">
                            <span>立项时间：</span>
                            <span>{{ info.projectApprovalTime }}</span>
                        </div>
                        <div class="insItem" style="display: flex;" v-if="info.fundraisingCode">
                            <span>募捐编号：</span>
                            <span style="margin-right: 8px;">{{ info.fundraisingCode }}</span>
                        </div>
                        <div class="insItem" v-if="info.projectCode">
                            <span>项目编码：</span>
                            <span>{{ info.projectCode }}</span>
                        </div>
                        <div class="insItem">
                            <span>收款机构：</span>
                            <span>{{ info.receivingInstitution }}</span>
                        </div>
                        <div class="insItem">
                            <span>执行机构：</span>
                            <span>{{ info.executeInstitution }}</span>
                        </div>
                        <div class="userItem flex acen">
                            <img :src="info.accountImage" mode="widthFix" />
                            <div class="userInfo">
                                <div class="name">
                                    <span>{{ info.accountName || '' }}</span><span>{{ info.accountRole || '' }}</span>
                                </div>
                                <div class="pos">
                                    {{ info.accountInstitution || '' }}
                                </div>
                            </div>
                        </div>
                        <div class="insItem">
                            <span>近期反馈：</span>
                            <span>{{ info.recentFeedback }}</span>
                        </div>
                        <div class="insItem">
                            <span>财务披露：</span>
                            <span>{{ info.financialDisclosure }}</span>
                        </div>
                        <div class="budget flex jbet acen">
                            <span>项目预算</span>
                            <span>{{ info.totalRaisedAmount }}元</span>
                        </div>
                        <div class="budList">
                            <div class="budItem flex jbet" v-for="(item, index) in info.projectBudget" :key="index">
                                <span>{{ item.title }}</span>
                                <span>{{ item.money }} 元</span>
                            </div>
                        </div>
                        <div class="cContent" style="padding-top: 15px;">
                            {{ info.budgetDetails }}
                        </div>
                        <div class="insTitle">
                            执行计划
                        </div>
                        <div class="cContent">
                            {{ info.implementationPlan }}
                        </div>
                        <div class="insTitle">
                            执行效果
                        </div>
                        <div class="cContent">
                            {{ info.executeEffect }}
                        </div>
                        <div class="insTitle">
                            捐赠回馈
                        </div>
                        <div class="cContent">
                            {{ info.donationFeedback }}
                        </div>
                        <div class="insTitle">
                            受益对象
                        </div>
                        <div class="cContent">
                            {{ info.benefitRecipients }}
                        </div>
                        <div class="insTitle">
                            项目地址
                        </div>
                        <div class="cContent">
                            {{ (info.provinceName || '') + (info.cityName || '')
                                + (info.areaName || '') + (info.streetName || '') + (info.communityName || '') + info.address }}
                        </div>
                        <div class="insTitle">
                            剩余财产处理方式
                        </div>
                        <div class="cContent">
                            {{ info.syPropertyHandle }}
                        </div>
                    </div>
                </div>
                <div class="card" id="choukuang">
                    <div class="ctitle">
                        筹款进度
                    </div>
                    <div class="schedule">
                        <div class="schTitle flex jbet">
                            <div class="left">
                                <div>已收到捐款</div>
                                <div>{{ info.raisedAmount }}元</div>
                            </div>
                            <div class="right">
                                <div>尚缺</div>
                                <div>
                                    {{ info.totalRaisedAmount - info.raisedAmount <= 0 ? 0 : info.totalRaisedAmount -
                                        info.raisedAmount }}元 </div>
                                </div>
                            </div>
                            <el-progress :text-inside="true" :stroke-width="20"
                                :percentage="parseInt((info.raisedAmount / info.totalRaisedAmount) * 100)"
                                status="exception"></el-progress>
                            <div class="schBom flex jbet">
                                <span>目标金额{{ info.totalRaisedAmount }}元</span>
                                <span v-if="parseInt((info.raisedAmount / info.totalRaisedAmount) * 100) > 0">{{
                                    info.raisedAmount / info.totalRaisedAmount > 1 ? 100 : parseInt((info.raisedAmount /
                                        info.totalRaisedAmount) * 100) }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div @click="lookEwm" v-loading="ewmLoading" v-if="!ewmimg" class="lookEwm">查看二维码</div>
                <div v-else class="ewmBox flex acen jcen">
                    <img v-if="ewmimg" :src="ewmimg" alt="">
                </div>
                <div @click="close" class="lookEwm">返回</div>

            </div>
    </el-drawer>
</template>

<script>
import { gyhaibao } from '@/api/index'
export default {
    name: 'proDrawer',
    data() {
        return {
            loading: false,
            ewmLoading: false,
            detailDrawer: false,
            info: {},
            ewmimg: ''
        }
    },
    methods: {
        lookEwm() {
            this.ewmLoading = true
            gyhaibao({ id: this.info.id }).then(res => {
                this.ewmLoading = false
                this.ewmimg = res.data.img
            }).catch(res => {
                this.ewmLoading = false
            })
        },
        close() {
            this.$refs.drawer.closeDrawer()
        }
    }
}
</script>

<style lang="less" scoped>
.ewmBox {
    img {
        width: 80%;
    }
}

.lookEwm {
    background: #FA5F69;
    color: #fff;
    margin: 20px;
    border-radius: 100px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.banner {
    width: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}

.main {
    position: relative;
    z-index: 100;
    margin-top: -60px;
    padding: 7px;

    .card {
        margin-bottom: 12px;
        background: linear-gradient(rgba(255, 255, 255, .8) 0%, #fff 20%);
        box-shadow: 0px 3px 17px 0px rgba(0, 0, 0, 0.1);
        border-radius: 13px;
        padding: 25px 8px;
        position: relative;

        .jk {
            position: absolute;
            top: 9px;
            right: -4px;
            width: 64px;
            // height: 20px;
        }

        .title {
            font-size: 22px;
            font-weight: bold;
            padding: 0 6px;
            color: #18181A;
            margin-bottom: 18px;
        }

        .more {
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            color: #3E4C55;
            margin-top: 15px;
        }

        .content {
            font-size: 13px;
            font-weight: 400;
            color: #545454;
            padding: 0 6px 25px;
            border-bottom: solid 1px #E8E8E8;
        }

        .jigou {
            margin-top: 16px;
            font-size: 13px;
            font-weight: 400;
            color: #545454;
            padding: 0 6px;

            &:last-child {
                margin-top: 6px;
            }

            img {
                width: 18px;
                height: 16px;
                margin: 0 5px 0 8px;
            }
        }

        .ctitle {
            font-size: 18px;
            font-weight: 500;
            color: #18181A;
            padding: 0 15px;
            margin-bottom: 18px;
            position: relative;

            &:before {
                position: absolute;
                left: 6px;
                top: 0;
                content: '';
                width: 3px;
                height: 100%;
                background: #FA5F69;
            }
        }

        .insMain {
            padding: 0 15px;

            .cContent {
                font-size: 14px;
            }

            .insItem {
                margin-bottom: 5px;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #3E4B61;

                    &:last-child {
                        font-size: 14px;
                        font-weight: 500;
                        color: #000;
                    }
                }
            }

            .budget {
                margin: 25px 0 15px;

                span {
                    font-size: 18px;
                    font-weight: 400;
                    color: #202938;

                    &:last-child {
                        font-size: 15px;
                        color: #000000;
                    }
                }
            }

            .budList {
                .budItem {
                    background: #EEEFF1;
                    border-radius: 3px;
                    padding: 13px;
                    margin-bottom: 8px;

                    span {
                        font-size: 14px;
                        font-weight: 500;
                        color: #020202;

                        &:last-child {
                            margin-left: 15px;
                            display: inline-block;
                            font-weight: normal;
                            color: #364653;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .insTitle {
                font-size: 18px;
                font-weight: 400;
                color: #202938;
                margin: 25px 0 15px;
            }

            .userItem {
                margin: 20px 0;

                img {
                    width: 50px;
                    // height: 50px;
                    border-radius: 5px;
                    margin-right: 20px;
                    flex-shrink: 0;
                }

                .userInfo {
                    .name {
                        span {
                            font-size: 14px;
                            font-weight: 500;
                            color: #000000;

                            &:last-child {
                                margin-left: 5px;
                                font-size: 13px;
                                color: #8A9495;
                            }
                        }
                    }

                    .pos {
                        font-size: 14px;
                        font-weight: 500;
                        color: #3E4B61;
                    }
                }
            }
        }




        .schedule {
            background: #F8F8F8;
            border-radius: 4px;
            padding: 25px;
            margin: 0 15px;

            .schTitle {
                margin-bottom: 15px;

                .left {
                    div {
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #3E4B61;

                        &:last-child {
                            font-size: 13px;
                            color: #000000;
                        }
                    }
                }

                .right {
                    div {
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        color: #3E4B61;

                        &:last-child {
                            font-size: 13px;
                            color: #666;
                        }
                    }
                }
            }

            .schBom {
                margin-top: 15px;

                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #3E4B61;

                    &:last-child {
                        font-weight: 500;
                    }
                }
            }
        }

        .logList {
            padding: 0 15px;

            .logItem {
                margin-bottom: 20px;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }

                .name {
                    font-size: 14px;
                    font-weight: 500;
                    color: #18181A;
                    margin: 0 5px;
                    width: 60px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .num {
                    font-size: 13px;
                    font-weight: 400;
                    color: #3E4B61;
                }

                .time {
                    font-size: 12px;
                    font-weight: 400;
                    color: #3E4B61;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    /deep/ .el-drawer.rtl {
        width: 100% !important;
    }
}
</style>