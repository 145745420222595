import Vue from "vue";
import Vuex from "vuex";
import { getHomeCataloguesList } from "@/api/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeIndex: "0",
    allMenuList:[],
    headerList: [],
    footerList: [],
    indexInfoList: [],
    infoSideList:[],
    indexProjectList: [],
    indexFundList:[],
    indexPolicyList:[],
    indexDtList:[]
  },
  getters: {
    activeIndex: (state) => state.activeIndex,
    allMenuList: (state) => state.allMenuList,
    headerList: (state) => state.headerList,
    footerList: (state) => state.footerList,
    indexInfoList: (state) => state.indexInfoList,
    infoSideList: (state) => state.infoSideList,
    indexProjectList: (state) => state.indexProjectList,
    indexFundList: (state) => state.indexFundList,
    indexPolicyList: (state) => state.indexPolicyList,
    indexDtList: (state) => state.indexDtList,
  },
  mutations: {
    
    SET_MENUINDEX: (state, activeIndex) => {
      state.activeIndex = activeIndex;
    },
    SET_ALLMENU: (state, menu) => {
      state.allMenuList = menu;
    },
    SET_HEADERMENU: (state, menu) => {
      state.headerList = menu;
    },
    SET_FOOTERMENU: (state, menu) => {
      state.footerList = menu;
    },
    SET_INDEXINFO: (state, menu) => {
      state.indexInfoList = menu;
    },
    SET_SIDEINFO: (state, menu) => {
      state.infoSideList = menu;
    },
    SET_INDEXPROJECT: (state, menu) => {
      state.indexProjectList = menu;
    },
    SET_INDEXFUND: (state, menu) => {
      state.indexFundList = menu;
    },
    SET_INDEXPOLICY: (state, menu) => {
      state.indexPolicyList = menu;
    },
    SET_INDEXDT: (state, menu) => {
      state.indexDtList = menu;
    },
  },
  actions: {
    // 获取菜单
    GetMenu({ commit, state }, type) {
      return new Promise((resolve, reject) => {
        getHomeCataloguesList({ type })
          .then((res) => {
            let infoList = res.data.data.filter((item) => item.id == 27)[0].childrenList.filter(item=>item.id==69||item.id==71||item.id==40||item.id==65||item.id==66);
            let infoSideList = res.data.data.filter((item) => item.id == 27)[0].childrenList;
            let projectList = res.data.data.filter((item) => item.id == 18)[0].childrenList.filter(item=>item.id==70||item.id==45);
            let fundList = res.data.data.filter((item) => item.id == 88)[0]?.childrenList;
            let policyList = res.data.data.filter((item) => item.id == 22)[0].childrenList;
            let dtList = res.data.data.filter((item) => item.id == 2)[0]?.childrenList.filter(item=>item.id==74||item.id==77||item.id==75);

            
            commit("SET_INDEXINFO", infoList);
            commit("SET_SIDEINFO", infoSideList);
            commit("SET_INDEXPROJECT", projectList);
            commit("SET_INDEXFUND", fundList);
            commit("SET_INDEXPOLICY", policyList);
            commit("SET_INDEXDT", dtList);

            commit("SET_ALLMENU", res.data.data);
            
            // if (type == 0) {
            // } else if (type == 1) {
            //   commit("SET_HEADERMENU", res.data.data);
            // } else {
            //   commit("SET_FOOTERMENU", res.data.data);
            // }
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
});
