import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/list.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/imglist',
    name: 'imglist',
    component: () => import('../views/imglist.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/projectlist',
    name: 'projectlist',
    component: () => import('../views/projectlist.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/donationForm',
    name: 'donationForm',
    component: () => import('../views/donation_form.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/expendForm',
    name: 'expendForm',
    component: () => import('../views/expend_form.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  },{
    path: '/message',
    name: 'message',
    component: () => import('../views/message_board.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  }
  ,{
    path: '/digitalize',
    name: 'digitalize',
    component: () => import('../views/digitalize.vue'),
    meta: {
      keepAlive: true // 需要缓存
    }
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  // 对于页面跳转，全部都返回到页面顶部。
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  }
})

export default router
